import { get } from 'lodash';

import * as AT from '../../action-types';
import { patientIdUrlParamName, baseUrlParamName, companyIdUrlParamName, patientTypeParamName } from '../../../consts';

const INIT_STATE = {
  config: {},
  fromUrl: {
    endpoint: '',
    companyId: '',
    patientId: '',
    patientType: ''
  },
  showLoader: true
};

const featureName = AT.SHELL;

const appConfigReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  const feature = get(action, 'meta.feature');

  if (feature !== featureName) {
    return state;
  }

  switch (type) {
    case AT.SET_APP_CONFIG:
      return {
        ...state,
        config: payload
      };
    case AT.SET_URL_PARAMS:
      return {
        ...state,
        fromUrl: {
          endpoint: payload[baseUrlParamName],
          patientId: payload[patientIdUrlParamName],
          companyId: payload[companyIdUrlParamName],
          patientType: payload[patientTypeParamName]
        }
      };
    case AT.SET_SHOW_LOADER:
      return { ...state, showLoader: payload };
    default:
      return state;
  }
};

export default appConfigReducer;
