import React from 'react';
import Button from 'react-bootstrap/Button';

import Popup from '../Popup';
import { NotificationIcons } from '../Popup/Notification/notificationIconsTypes';

import styles from './DangerPopup.module.css';

function DangerPopup(props) {
  const { confirmText, message, title, handleConfirmClick } = props;

  const icon = NotificationIcons.Danger;

  const footerActions = (
    <>
      <Button
        className={styles.footerButton}
        onClick={handleConfirmClick}
        variant="primary"
        size="lg"
      >
        {confirmText}
      </Button>
    </>
  );

  const messageDanger = <div className={styles.message}>{message}</div>;

  return (
    <Popup
      title={title}
      message={messageDanger}
      icon={icon}
      footerActions={footerActions}
    />
  );
}

export default DangerPopup;
