import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import genericStyles from '../../UI/GenericUiStyles.module.scss';
import styles from './DateInput.module.scss';

function DateInput(props) {
  const {
    name,
    value,
    readOnly,
    onChange,
    touched,
    onFocus,
    active,
    error,
    format
  } = props;
  const isError = !active && touched && error;
  return (
    <div className={styles['date-input']}>
      <InputMask
        name={name}
        value={value}
        disabled={readOnly}
        onChange={onChange}
        onFocus={onFocus}
        type="tel"
        placeholder={format}
        className={classNames(
          { [`${genericStyles['input-field-error']}`]: isError },
          { [`${genericStyles['no-border']}`]: !isError },
          'form-control'
        )}
        mask="9999-99-99"
        maskChar={null}
        permanents="[5,8]"
      />
    </div>
  );
}

DateInput.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func
};

export default DateInput;
