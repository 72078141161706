export const patientSearchTranslatedStrings = {
  cancel: {
    id: 'patientSearch.cancel',
    defaultMessage: 'Cancel'
  },
  yes: {
    id: 'patientSearch.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'patientSearch.no',
    defaultMessage: 'No'
  },
  pairMessage: {
    id: 'patientSearch.pairMessage',
    defaultMessage: 'A patient with the same details already exists:'
  },
  pairMessageQuestion: {
    id: 'patientSearch.pairMessageQuestion',
    defaultMessage: 'Is this the same patient?'
  },
  question: {
    id: 'patientSearch.question',
    defaultMessage: 'Question'
  },
  selectPatient: {
    id: 'patientSearch.selectPatient',
    defaultMessage: 'Select Patient'
  },
  searchPatient: {
    id: 'patientSearch.searchPatient',
    defaultMessage: 'Search Patient'
  },
  fullName: {
    id: 'patientSearch.fullName',
    defaultMessage: 'Full Name'
  },
  gender: {
    id: 'patientSearch.gender',
    defaultMessage: 'Gender'
  },
  male: {
    id: 'patientSearch.male',
    defaultMessage: 'Male'
  },
  female: {
    id: 'patientSearch.female',
    defaultMessage: 'Female'
  },
  dateOfBirth: {
    id: 'patientSearch.dateOfBirth',
    defaultMessage: 'Date of Birth'
  },
  alignPatientId: {
    id: 'patientSearch.alignPatientId',
    defaultMessage: 'Align Patient ID'
  },
  chartNumber: {
    id: 'patientSearch.chartNumber',
    defaultMessage: 'Chart #'
  },
  typeAtLeast3Letters: {
    id: 'patientSearch.typeAtLeast3Letters',
    defaultMessage: 'Type at least 3 letters'
  },
  noResults: {
    id: 'patientSearch.noResults',
    defaultMessage: 'No Results'
  },
  zipCode: {
    id: 'patientSearch.zipCode',
    defaultMessage: 'Zip Code #'
  }
};
