import { combine } from 'redux-form-validators';
import { patientFormTranslatedStrings } from '../../translations/PatientFormTranslations';
import validators from '../../validation/validators';

const { firstName, lastName, dateOfBirth, chartNumber, zipCodeErrorName } = patientFormTranslatedStrings;

var maxPatientLastNameLength = 25;

const maxPatientFirstNameLength = 25;
const maxChartNumberLength = 20;
const maxZipCodeLength = 7;

var formFieldsValidations = {
  firstName: {
    validations: combine(
      validators.requiredError(),
      validators.lengthError(firstName, maxPatientFirstNameLength),
      validators.emojiPatternError(firstName)
    )
  },
  lastName: {
    validations: combine(
      validators.requiredError(),
      validators.lengthError(lastName, maxPatientLastNameLength),
      validators.emojiPatternError(lastName)
    )
  },
  mi: {
    validations: combine(validators.middleInitialLengthError(), validators.middleInitialCharactersOnlyError())
  },
  gender: {
    validations: []
  },
  genderRequired: {
    validations: [validators.requiredError()]
  },
  dateOfBirth: {
    validations: [validators.DateFieldError(dateOfBirth)]
  },
  dateOfBirthRequired: {
    validations: combine(validators.requiredError(), validators.DateFieldError(dateOfBirth))
  },
  chartNumber: {
    validations: combine(
      validators.lengthError(chartNumber, maxChartNumberLength),
      validators.emojiPatternError(chartNumber)
    )
  },
  zipCode: {
    validations: combine(
      validators.lengthError(zipCodeErrorName, maxZipCodeLength),
      validators.patientZipCodePatternError(zipCodeErrorName)
    )
  },
  zipCodeRequired: {
    validations: combine(
      validators.requiredError(),
      validators.lengthError(zipCodeErrorName, maxZipCodeLength),
      validators.patientZipCodePatternError(zipCodeErrorName)
    )
  }
};

export const fieldValidations = fieldName => formFieldsValidations[fieldName].validations;

export const updateLastNameMaxLength = maxLength => {
  maxPatientLastNameLength = maxLength;
  if (maxPatientLastNameLength === 1) {
    formFieldsValidations.lastName.validations = combine(
      validators.requiredError(),
      validators.minimalLengthError(lastName, maxPatientLastNameLength),
      validators.emojiPatternError(lastName)
    );
  } else {
    formFieldsValidations.lastName.validations = combine(
      validators.requiredError(),
      validators.lengthError(lastName, maxPatientLastNameLength),
      validators.emojiPatternError(lastName)
    );
  }
}

export default formFieldsValidations;
