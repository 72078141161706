import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TranslatedContent from '../../Translations/TranslatedContent';

import styles from './RadioButton.module.scss';

function RadioButton(props) {
  const { name, value, readOnly, onChange, touched, error } = props;

  return (
    <div className="form-control" key={`${name}wrapper`}>
      <div className={classNames(`form-check form-check-inline ${styles['form-check-wrapper']}`)}>
        {props.options.map(option => (
          <div key={`${option.id}wrapper`} className={classNames('pr-5', styles['radio-input-wrapper'])}>
            <input
              type="radio"
              className={classNames(`form-check-input ${styles['radio-btn']}`, {
                'input-field-error': touched && error
              })}
              id={option.id}
              value={option.value}
              name={name}
              disabled={readOnly}
              onChange={onChange}
              checked={(value || Number.isInteger(value)) && value.toString() === option.value.toString()}
            />
            <label className="form-check-label align-top" htmlFor={option.id}>
              <TranslatedContent contentArray={[option.label]} />
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

RadioButton.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
};

export default RadioButton;
