import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Button } from 'react-bootstrap';
import { debounce } from 'lodash';
import QuestionPopup from '../UI/QuestionPopup';
import DangerPopup from '../UI/DangerPopup';
import { patientFormTranslatedStrings as messages } from '../../translations/PatientFormTranslations';
import {
  userClickedOnConfirmErrorGeneralPopup,
  userClickedOnCancelConflictPopup,
  userClickedOnConfirmConflictPopup,
  userClickedOnCancelPatientForm,
  userClickedOnSubmitPatientForm,
  userChangedInputPatient
} from '../../redux/feature/patient-management/patient-management.actions';
import TranslatedContent from '../Translations/TranslatedContent';
import DynamicInputField from '../DynamicInputField/DynamicInputField';
import Alert from '../UI/Alert';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import utils from '../../utils/utils';
import styles from './PatientManagementForm.module.scss';
import { FieldName } from '../../utils/enums';

class PatientForm extends React.Component {
  static propTypes = {
    /**
     * https://redux-form.com/8.2.2/docs/api/props.md/#-code-submitting-boolean-code-
     */
    submitting: PropTypes.bool.isRequired,
    /**
     * https://redux-form.com/8.2.2/docs/api/props.md/#-code-invalid-boolean-code-
     */
    invalid: PropTypes.bool.isRequired,
    /**
     * https://redux-form.com/8.2.2/docs/api/props.md/#-code-invalid-boolean-code-
     */
    patientId: PropTypes.string,
    companyId: PropTypes.string,
    patientType: PropTypes.string,
    config: PropTypes.object,
    initialValues: PropTypes.object,
    formValues: PropTypes.object,
    formFields: PropTypes.array.isRequired,
    conflictedPatient: PropTypes.object,
    patientCancelButtonVariant: PropTypes.string,
    errorConflictAlertVariant: PropTypes.string,
    formHeaderTitleTranslation: PropTypes.object,
    patientCancelButtonTranslation: PropTypes.object,
    patientSubmitButtonTranslation: PropTypes.object,
    errorGeneralMessageTranslation: PropTypes.object,
    isEditPatient: PropTypes.bool.isRequired,
    isReadOnlyForm: PropTypes.bool.isRequired,
    isPatientSubmitDisabled: PropTypes.bool,
    isErrorConflictAlert: PropTypes.bool.isRequired,
    isErrorConflictPopup: PropTypes.bool.isRequired,
    isErrorGeneralPopup: PropTypes.bool.isRequired,
    userClickedOnConfirmErrorGeneralPopup: PropTypes.func.isRequired,
    userClickedOnCancelConflictPopup: PropTypes.func.isRequired,
    userClickedOnConfirmConflictPopup: PropTypes.func.isRequired,
    userClickedOnCancelPatientForm: PropTypes.func.isRequired,
    userClickedOnSubmitPatientForm: PropTypes.func.isRequired,
    userChangedInputPatient: PropTypes.func.isRequired,
    isGeneratedChartNumberRequired: PropTypes.bool
  };

  componentDidMount() {
    window.addEventListener('touchmove', this.handleScroll);
  }

  componentDidUpdate() {
    this.updateGeneratedChartNumber(this.props.formValues);
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleScroll);
  }

  getConflictPatientDetails = () => {
    const { conflictedPatient } = this.props;
    if (!conflictedPatient || !conflictedPatient.fullName) {
      return '';
    }

    const isGender = Number.isInteger(conflictedPatient.gender);
    const dateOfBirth = conflictedPatient.dateOfBirth;
    const chartNumber = conflictedPatient.chartNumber;

    const msg = (
      <>
        {<TranslatedContent contentArray={[conflictedPatient.fullName.trim()]} />}
        {isGender && ', '}
        {isGender && <TranslatedContent contentArray={[conflictedPatient.gender ? messages.female : messages.male]} />}
        {dateOfBirth && ', '}
        {dateOfBirth && utils.formatDateString(conflictedPatient.dateOfBirth)}
        {chartNumber && ', '}
        {chartNumber && <TranslatedContent contentArray={[conflictedPatient.chartNumber]} />}
      </>
    );
    return msg;
  };

  getConflictPopupMessage = () => {
    const msg = (
      <>
        <TranslatedContent contentArray={[messages.conflictPopupMessage]} />
        <br />
        {this.getConflictPatientDetails()}
        <br />
        <br />
        <TranslatedContent contentArray={[messages.conflictPopupQuestion]} />
      </>
    );
    return msg;
  };

  updateGeneratedChartNumber = (formValues) => {
    if (this.props.isGeneratedChartNumberRequired) {
      const generatedChartNumber = (formValues.firstName ? formValues.firstName[0] : '')
        + (formValues.lastName ? formValues.lastName[0] : '')
        + initialChartNumber;
      this.props.change(FieldName.CHART_NUMBER, generatedChartNumber);
    }
  };

  handleChange = debounce((e, isKeyField) => {
    const { formValues, companyId, isEditPatient, formLastInvalid, invalid, userChangedInputPatient } = this.props;
    this.updateGeneratedChartNumber(formValues);
    userChangedInputPatient({ form: formValues, companyId, isEditPatient, isKeyField, formLastInvalid, invalid });
  }, 300);

  handleFormSubmit = e => {
    const { formValues, patientId, companyId, conflictedPatient, userClickedOnSubmitPatientForm } = this.props;
    e.preventDefault();
    userClickedOnSubmitPatientForm({ form: formValues, patientId, companyId, conflictedPatient });
  };

  handleScroll = e => {
    document.activeElement.blur();
  };

  handleConflictPopupConfirm = () => {
    const { formValues, patientId, patientType, companyId, conflictedPatient, userClickedOnConfirmConflictPopup } = this.props;
    userClickedOnConfirmConflictPopup({ form: formValues, patientId, patientType, companyId, conflictedPatient });
  };

  renderField = fieldData => {
    return <DynamicInputField {...fieldData} showErrorBorder={fieldData.formFields.isShowErrorBorder} />;
  };

  render() {
    const {
      submitting,
      invalid,
      formFields,
      conflictedPatient,
      patientCancelButtonVariant,
      errorConflictAlertVariant,
      isReadOnlyForm,
      isPatientSubmitDisabled,
      isErrorConflictAlert,
      isErrorConflictPopup,
      isErrorGeneralPopup,
      formHeaderTitleTranslation,
      patientCancelButtonTranslation,
      patientSubmitButtonTranslation,
      errorGeneralMessageTranslation,
      userClickedOnCancelPatientForm,
      userClickedOnCancelConflictPopup,
      userClickedOnConfirmErrorGeneralPopup
    } = this.props;

    const footerActions = (
      <>
        <Button
          className={styles.cancelButton}
          onClick={userClickedOnCancelPatientForm}
          variant={patientCancelButtonVariant}
          size="lg"
        >
          <TranslatedContent contentArray={[patientCancelButtonTranslation]} />
        </Button>
        {!isReadOnlyForm && (
          <Button
            className={styles.submitButton}
            disabled={invalid || submitting || isPatientSubmitDisabled}
            type="submit"
            variant="primary"
            size="lg"
          >
            <TranslatedContent contentArray={[patientSubmitButtonTranslation]} />
          </Button>
        )}
      </>
    );

    return (
      <>
        <form onSubmit={this.handleFormSubmit} onScroll={this.handleScroll}>
          <Header title={<TranslatedContent contentArray={[formHeaderTitleTranslation]} />} />

          {formFields.map(
            field =>
              field.isVisible && (
                <Field
                  key={field.name}
                  name={field.name}
                  component={this.renderField}
                  validate={field.validations}
                  formFields={field}
                  props={{ conflictedPatient, readOnly: isReadOnlyForm || field.isDisabled }}
                  onChange={e => this.handleChange(e, field.isKeyField)}
                />
              )
          )}

          <div className={styles.alert}>
            {isErrorConflictAlert && conflictedPatient && !isReadOnlyForm && (
              <Alert
                variant={errorConflictAlertVariant}
                heading={<TranslatedContent contentArray={[messages.conflictHeader]} />}
                content={this.getConflictPatientDetails()}
              />
            )}
          </div>

          <Footer className={styles.footer} actions={footerActions} />
        </form>
        {isErrorConflictPopup && conflictedPatient && (
          <QuestionPopup
            handleCancelClick={userClickedOnCancelConflictPopup}
            cancelText={<TranslatedContent contentArray={[messages.no]} />}
            handleConfirmClick={this.handleConflictPopupConfirm}
            confirmText={<TranslatedContent contentArray={[messages.yes]} />}
            title={<TranslatedContent contentArray={[messages.question]} />}
            message={this.getConflictPopupMessage()}
          />
        )}
        {isErrorGeneralPopup && (
          <DangerPopup
            handleConfirmClick={userClickedOnConfirmErrorGeneralPopup}
            confirmText={<TranslatedContent contentArray={[messages.buttonOk]} />}
            title={<TranslatedContent contentArray={[messages.errorGeneralHeader]} />}
            message={<TranslatedContent contentArray={[errorGeneralMessageTranslation]} />}
          />
        )}
      </>
    );
  }
}

const formName = 'PatientForm';

const initialChartNumber = Math.floor((new Date().valueOf() / 1000 / 60) % 1000000).toString();

const mapStateToProps = state => {
  const { patientManagement, shell } = state;
  return {
    patientId: shell.fromUrl.patientId,
    companyId: shell.fromUrl.companyId,
    patientType: shell.fromUrl.patientType,
    config: shell.config,
    initialValues: patientManagement.initialPatientData,
    formValues: getFormValues(formName)(state),
    formFields: patientManagement.formFields,
    conflictedPatient: patientManagement.conflictedPatient,
    patientCancelButtonVariant: patientManagement.patientCancelButtonVariant,
    errorConflictAlertVariant: patientManagement.errorConflictAlertVariant,
    formHeaderTitleTranslation: patientManagement.formHeaderTitleTranslation,
    patientCancelButtonTranslation: patientManagement.patientCancelButtonTranslation,
    patientSubmitButtonTranslation: patientManagement.patientSubmitButtonTranslation,
    errorGeneralMessageTranslation: patientManagement.errorGeneralMessageTranslation,
    formLastInvalid: patientManagement.formLastInvalid,
    isEditPatient: patientManagement.isEditPatient,
    isReadOnlyForm: patientManagement.isReadOnlyForm,
    isPatientSubmitDisabled: patientManagement.isPatientSubmitDisabled,
    isErrorConflictAlert: patientManagement.isErrorConflictAlert,
    isErrorConflictPopup: patientManagement.isErrorConflictPopup,
    isErrorGeneralPopup: patientManagement.isErrorGeneralPopup,
    isGeneratedChartNumberRequired: patientManagement.isGeneratedChartNumberRequired
  };
};

const mapDispatchToProps = {
  userClickedOnConfirmErrorGeneralPopup,
  userClickedOnCancelConflictPopup,
  userClickedOnConfirmConflictPopup,
  userClickedOnCancelPatientForm,
  userClickedOnSubmitPatientForm,
  userChangedInputPatient
};

PatientForm = reduxForm({
  form: formName,
  touchOnBlur: true,
  touchOnChange: true,
  enableReinitialize: true
})(PatientForm);

PatientForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientForm);

export default PatientForm;
