import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types'; 
import { Form } from 'react-bootstrap';

import genericStyles from '../../UI/GenericUiStyles.module.scss';
import textInputStyles from './TextInput.module.scss';

function TextInput(props) {
  const { name, value, readOnly, onChange, touched, error } = props;
  const isError = touched && error;
  return (
    <div>
      <Form.Control
        name={name}
        value={value}
        disabled={readOnly}
        onChange={onChange}
        type="text"
        className={classNames(
          { [`${genericStyles['input-field-error']}`]: isError },
          { [`${genericStyles['no-border']}`]: !isError },
          'form-control',`${textInputStyles['form-control']}`
        )}
      />
    </div>
  );
}

TextInput.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func
};

export default TextInput;
