import * as AT from '../../action-types';
import { urls } from '../../../consts';
import utils from '../../../utils/utils';

const { patientsGetAllUrl,patientSaveUrl } = urls;
const { arrayToQueryString  } = utils;

export const getPatients = ({
  patientTypes,
  forceCacheUpdate = false,
  setLoader = false,
  cancelPrevRequest = false
}) => {
  const url = `${patientsGetAllUrl}?forceCacheUpdate=${forceCacheUpdate}${arrayToQueryString(
    patientTypes,
    'patientTypes'
  )}`;
  return {
    type: AT.GET_PATIENTS.API_REQUEST,
    meta: {
      withCredentials: true,
      feature: AT.PATIENT_SEARCH,
      sourceAction: AT.GET_PATIENTS,
      url,
      method: 'GET',
      setLoader,
      cancelPrevRequest
    }
  };
};

export const userClickedOnSelectPatient = (selectedPatient, companyId) => ({
  type: AT.USER_CLICKED_ON_SELECT_PATIENT,
  payload: { patient: selectedPatient, companyId },
  meta: {
    feature: AT.PATIENT_SEARCH
  }
});

export const userDecideToPairPatient = (patient, companyId) => ({
  type: AT.USER_DECIDE_TO_PAIR_PATIENT,
  payload: { patient, companyId },
  meta: {
    feature: AT.PATIENT_SEARCH
  }
});

export const userClickedOnCancelConflictPopup = () => ({
  type: AT.USER_CLICKED_ON_CANCEL_CONFLICT_POPUP,
  meta: {
    feature: AT.PATIENT_SEARCH
  }
});

export const setPatients = patients => ({
  type: AT.SET_PATIENTS,
  payload: patients,
  meta: {
    feature: AT.PATIENT_SEARCH
  }
});

export const updateHostPatient = patient => ({
  type: AT.UPDATE_HOST_PATIENT,
  payload: patient,
  meta: {
    feature: AT.PATIENT_SEARCH
  }
});

export const setConflictedPatient = conflictedPatient => ({
  type: AT.SET_CONFLICTED_PATIENT,
  payload: conflictedPatient,
  meta: {
    feature: AT.PATIENT_SEARCH
  }
});

export const savePatient = ({patient, companyId, setLoader = false, cancelPrevRequest = false}) => ({
  type: AT.SAVE_PATIENT.API_REQUEST,
  payload: patient,
  meta: {
    withCredentials: true,
    feature: AT.PATIENT_SEARCH,
    sourceAction: AT.SAVE_PATIENT,
    url: `${patientSaveUrl}?companyId=${companyId}`,
    method: 'POST',
    setLoader,
    cancelPrevRequest
  }
});
