import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import utils from '../../utils/utils';

function TranslatedContent(props) {
  const { contentArray } = props;

  const formatInput = content => {
    if (utils.isString(content)) {
      return <span key={content}>{content}</span>;
    }
    if (utils.isTranslationObject(content)) {
      return <FormattedMessage key={content.id} {...content} />;
    }
    return '';
  };

  return <>{contentArray.map(content => formatInput(content))}</>;
}

TranslatedContent.propTypes = {
  contentArray: PropTypes.array,
  intl: intlShape.isRequired
};

export default injectIntl(TranslatedContent);
