import { get } from 'lodash';
import * as AT from '../../action-types';
import patientUtils from './patient-management.utils';
import { patientFormTranslatedStrings as messages } from '../../../translations/PatientFormTranslations';
import createMiddleware from '../../utils/middleware-helper';
import { patientIdUrlParamName, patientTypeParamName, baseUrlParamName } from '../../../consts';

import { ApiStatusCodes, AlertVariant, ButtonVariant, FieldName } from '../../../utils/enums';
import { sdkCloseMe, sdkSendMessage } from '../../core/sdk/sdk.actions';
import { rxEventTypes } from '../../core/sdk/sdk.enums';
import { getMessageObject } from '../../utils';
import {
  getPatientById,
  setPatientById,
  setIsEditPatient,
  setIsReadOnlyForm,
  setIsPatientSubmitDisabled,
  setFormFields,
  setConflictedPatient,
  setPatientCancelButtonVariant,
  setErrorConflictAlertVariant,
  setFormHeaderTitleTranslation,
  setPatientCancelButtonTranslation,
  setPatientSubmitButtonTranslation,
  setErrorGeneralMessageTranslation,
  setFormLastInvalid,
  setIsErrorConflictAlert,
  setIsErrorConflictPopup,
  setIsErrorGeneralPopup,
  checkIfPatientHasConflictsNew,
  checkIfPatientHasConflictsEdit,
  updatePatient,
  savePatient,
  updateHostPatient,
} from './patient-management.actions';

const featureName = AT.PATIENT_MANAGEMENT;

const submitPatientForm = (dispatch, form, patientId, companyId, isUpdate) => {
  const patient = patientUtils.getPatientDto(form);
  if (patientId || isUpdate) {
    dispatch([updatePatient({ patient, companyId })]);
  } else {
    dispatch([savePatient({ patient, companyId })]);
  }
};

const checkIfPatientHasConflicts = (
  dispatch,
  form,
  isEditPatient,
  companyId,
  setIsLastInvalid = false,
  invalid = false
) => {
  const patientObj = patientUtils.getPatientDto(form);
  const cancelPrevRequest = true;
  let actions = setIsLastInvalid ? [setFormLastInvalid(invalid)] : [];
  if (isEditPatient) {
    actions.push(checkIfPatientHasConflictsEdit({ patient: patientObj, companyId, cancelPrevRequest }));
  } else {
    actions.push(checkIfPatientHasConflictsNew({ patient: patientObj, companyId, cancelPrevRequest }));
  }
  dispatch(actions);
};

export const middleware = ({ dispatch, getState, action }) => {
  const { type, payload } = action;
  const state = getState();

  switch (type) {
    case AT.GET_PATIENT_BY_ID.SUCCESS: {
      const { patients } = payload.data;
      const companyId = state.shell.fromUrl.companyId;
      const patientDto = patientUtils.getPatientDto(patients[0]);
      let actions = [
        setPatientById(patients[0]),
        setIsEditPatient(true),
        setErrorConflictAlertVariant(AlertVariant.DANGER),
        setPatientSubmitButtonTranslation(messages.buttonUpdate),
        setErrorGeneralMessageTranslation(messages.errorGeneralMessageEdit),
        checkIfPatientHasConflictsEdit({ patient: patientDto, companyId })
      ];
      const isReadOnlyForm = patientUtils.isIDSPatient(patientDto.type);
      if (isReadOnlyForm) {
        actions = actions.concat([
          setIsReadOnlyForm(true),
          setPatientCancelButtonVariant(ButtonVariant.PRIMARY),
          setFormHeaderTitleTranslation(messages.headerEditDisabled),
          setPatientCancelButtonTranslation(messages.buttonOk)
        ]);
      } else {
        actions.push(setFormHeaderTitleTranslation(messages.headerEdit));
      }
      dispatch(actions);
      break;
    }
    case AT.CHANGE_PATIENT_VALIDATIONS_CONFIG: {
      const newFormFields = patientUtils.getNewFormFieldsRequirements(state.patientManagement.formFields, payload);
      dispatch([setFormFields(newFormFields)]);
      break;
    }
    case AT.SET_ZIP_CODE_VISIBILITY: {
      const newFormFields = patientUtils.getNewFormFieldsVisibility(state.patientManagement.formFields, payload);
      dispatch([setFormFields(newFormFields)]);
      break;
    }
    case AT.SET_CHART_NUMBER_AVAILABILITY: {
      const newFormFields = patientUtils.getNewFormFieldsAvailability(state.patientManagement.formFields, FieldName.CHART_NUMBER, payload);
      dispatch([setFormFields(newFormFields)]);
      break;
    }
    case AT.SET_LAST_NAME_VALIDATION: {
      const newFormFields = patientUtils.getNewFormFieldsValidations(state.patientManagement.formFields, payload);
      dispatch([setFormFields(newFormFields)]);
      break;
    }
    case AT.CHECK_IF_PATIENT_HAS_CONFLICTS_NEW.SUCCESS:
    case AT.CHECK_IF_PATIENT_HAS_CONFLICTS_EDIT.SUCCESS: {
      // success means that the patient has no conflicts at all
      const newFormFields = patientUtils.getNewFormFieldsIsShowErrorBorder(
        state.patientManagement.formFields,
        state.patientManagement.isReadOnlyForm,
        null
      );
      let actions = [setIsErrorConflictAlert(false), setConflictedPatient(null), setFormFields(newFormFields)];
      if (state.patientManagement.isEditPatient) {
        actions.push(setIsPatientSubmitDisabled(false));
      }
      dispatch(actions);
      break;
    }
    case AT.CHECK_IF_PATIENT_HAS_CONFLICTS_NEW.ERROR:
    case AT.CHECK_IF_PATIENT_HAS_CONFLICTS_EDIT.ERROR: {
      const conflictedPatient = patientUtils.getPatientConflict(payload);
      if (conflictedPatient) {
        const conflictedPatient = get(payload, 'error.data.conflictedPatients[0]');
        const newFormFields = patientUtils.getNewFormFieldsIsShowErrorBorder(
          state.patientManagement.formFields,
          state.patientManagement.isReadOnlyForm,
          conflictedPatient
        );
        const actions = [
          setConflictedPatient(conflictedPatient),
          setIsErrorConflictAlert(true),
          setFormFields(newFormFields)
        ];
        if (state.patientManagement.isEditPatient) {
          actions.push(setIsPatientSubmitDisabled(true));
        }
        dispatch(actions);
      }
      break;
    }

    case AT.SAVE_PATIENT.API_REQUEST: {
      dispatch([setIsErrorConflictPopup(false)]);
      break;
    }

    case AT.SAVE_PATIENT.SUCCESS: {
      const patientResponse = payload.data;
      if (patientResponse.statusCode === ApiStatusCodes.CREATED) {
        dispatch([
          setIsErrorConflictAlert(false),
          updateHostPatient(patientResponse.patients && patientResponse.patients[0])
        ]);
      }
      break;
    }

    case AT.SAVE_PATIENT.ERROR:
    case AT.UPDATE_PATIENT.ERROR: {
      const conflictedPatient = patientUtils.getPatientConflict(payload);
      if (conflictedPatient) {
        dispatch([
          setIsErrorGeneralPopup(false),
          setIsErrorConflictAlert(true),
          setIsErrorConflictPopup(true),
          setConflictedPatient(conflictedPatient)
        ]);
      } else {
        dispatch([
          setIsErrorConflictAlert(false),
          setIsErrorConflictPopup(false),
          setConflictedPatient(null),
          setIsErrorGeneralPopup(true)
        ]);
      }
      break;
    }

    case AT.UPDATE_PATIENT.SUCCESS: {
      const patientResponse = payload.data;
      if (patientResponse.statusCode === ApiStatusCodes.SUCCESS) {
        dispatch([
          setIsErrorConflictAlert(false),
          updateHostPatient(patientResponse.patients && patientResponse.patients[0])
        ]);
      }
      break;
    }

    case AT.USER_CLICKED_ON_CONFIRM_ERROR_GENERAL_POPUP: {
      dispatch([setIsErrorGeneralPopup(false)]);
      break;
    }

    case AT.USER_CLICKED_ON_CANCEL_CONFLICT_POPUP: {
      // TBD:
      // if the doctor decide it is isn't the same patient suggested in the dialog,
      // we should act according to the following scenraios:
      dispatch([setIsErrorConflictPopup(false)]);
      break;
    }

    case AT.USER_CLICKED_ON_CANCEL_PATIENT_FORM: {
      dispatch([sdkCloseMe(rxEventTypes.patientAppAddPatient)]);
      break;
    }

    case AT.USER_CLICKED_ON_SUBMIT_PATIENT_FORM: {
      const { form, patientId, companyId, conflictedPatient } = payload;
      const capitalizeValue = text => text.split(' ').map(textUnit => `${textUnit.charAt(0).toUpperCase()}${textUnit.substring(1)}`).join(' ');
      const { firstName, lastName } = { firstName: capitalizeValue(form.firstName), lastName: capitalizeValue(form.lastName) };
      const newForm = { ...form, firstName, lastName, dateOfBirth: form[FieldName.DATE_OF_BIRTH] };
      if (conflictedPatient) {
        dispatch([setIsErrorConflictPopup(true)]);
      } else {
        submitPatientForm(dispatch, newForm, patientId, companyId);
      }
      break;
    }

    case AT.USER_CLICKED_ON_CONFIRM_CONFLICT_POPUP: {
      const { form, patientId, companyId, conflictedPatient } = payload;
      const newForm = { ...form, ...conflictedPatient };
      const isUpdate =
        conflictedPatient && conflictedPatient.type && patientUtils.isIteroPatient(+conflictedPatient.type);
      submitPatientForm(dispatch, newForm, patientId, companyId, isUpdate);
      break;
    }

    case AT.USER_CHANGED_INPUT_PATIENT: {
      const { form, companyId, isEditPatient, isKeyField, formLastInvalid, invalid } = payload;
      const isFormInvalidChanged = invalid !== formLastInvalid;
      if (invalid) {
        if (isFormInvalidChanged) {
          dispatch([setFormLastInvalid(invalid)]);
        }
        return;
      }
      if (!isKeyField && !isFormInvalidChanged) {
        return;
      }
      checkIfPatientHasConflicts(dispatch, form, isEditPatient, companyId, isFormInvalidChanged, invalid);
      break;
    }

    case AT.USER_CLICKED_ON_AN_ALREADY_PAIRED_PATIENT: {
      const { patient } = payload;
      dispatch([updateHostPatient(patient)]);
      break;
    }

    case AT.UPDATE_HOST_PATIENT: {
      const message = getMessageObject();
      message.data = {
        eventId: rxEventTypes.patientAppUpdateHostPatient,
        data: payload
      };
      dispatch([sdkSendMessage(message)]);
      break;
    }

    default:
    // do nothing
  }
};

export const goThroughOverride = ({ dispatch, getState, action }) => {
  const { type, payload } = action;

  switch (type) {
    case AT.APP_READY: {
      // go to server to bring some initial data for the patientManagement
      // goes here
      break;
    }
    case AT.CHANGE_URL_PARAMS: {
      const urlParams = payload;
      if (urlParams) {
        const patientId = urlParams[patientIdUrlParamName];
        const patientType = urlParams[patientTypeParamName];
        const endpoint = urlParams[baseUrlParamName];
        if (patientUtils.isValidPatientUrl(patientId, patientType, endpoint)) {
          dispatch([getPatientById({ id: patientId, type: patientType, endpoint })]);
        } else if (patientUtils.isLogUrlParams(patientId, patientType, endpoint)) {
          console.error('Wrong patient details in edit patient url');
        }
      }
      break;
    }
    default:
    // do nothing
  }
};

export default createMiddleware({
  feature: featureName,
  goThroughOverride
})(middleware);
