import * as AT from '../../action-types';
import { urls } from '../../../consts';

const { patientsGetByIdUrl, patientSaveUrl, patientUpdateByIdUrl } = urls;

export const getPatientById = ({ id, type, endpoint, setLoader = true, cancelPrevRequest = false }) => ({
  type: AT.GET_PATIENT_BY_ID.API_REQUEST,
  payload: { id: id, type: type },
  meta: {
    withCredentials: true,
    feature: AT.PATIENT_MANAGEMENT,
    sourceAction: AT.GET_PATIENT_BY_ID,
    endpoint,
    url: patientsGetByIdUrl,
    method: 'GET',
    setLoader,
    cancelPrevRequest
  }
});

export const savePatient = ({ patient, companyId, setLoader = true, cancelPrevRequest = false }) => ({
  type: AT.SAVE_PATIENT.API_REQUEST,
  payload: patient,
  meta: {
    withCredentials: true,
    feature: AT.PATIENT_MANAGEMENT,
    sourceAction: AT.SAVE_PATIENT,
    url: `${patientSaveUrl}?companyId=${companyId}`,
    method: 'POST',
    setLoader,
    cancelPrevRequest
  }
});

export const updatePatient = ({ patient, companyId, setLoader = true, cancelPrevRequest = false }) => ({
  type: AT.UPDATE_PATIENT.API_REQUEST,
  payload: patient,
  meta: {
    withCredentials: true,
    feature: AT.PATIENT_MANAGEMENT,
    sourceAction: AT.UPDATE_PATIENT,
    url: `${patientUpdateByIdUrl}?UID=${patient.uid}&companyId=${companyId}`,
    method: 'PATCH',
    setLoader,
    cancelPrevRequest
  }
});

export const setPatientById = patient => ({
  type: AT.SET_PATIENT_BY_ID,
  payload: patient,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setFormFields = formFields => ({
  type: AT.SET_FORM_FIELDS,
  payload: formFields,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setConflictedPatient = conflictedPatient => ({
  type: AT.SET_CONFLICTED_PATIENT,
  payload: conflictedPatient,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setPatientCancelButtonVariant = patientCancelButtonVariant => ({
  type: AT.SET_PATIENT_CANCEL_BUTTON_VARIANT,
  payload: patientCancelButtonVariant,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setErrorConflictAlertVariant = errorConflictAlertVariant => ({
  type: AT.SET_ERROR_CONFLICT_ALERT_VARIANT,
  payload: errorConflictAlertVariant,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setFormHeaderTitleTranslation = value => ({
  type: AT.SET_FORM_HEADER_TITLE_TRANSLATION,
  payload: value,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setPatientCancelButtonTranslation = value => ({
  type: AT.SET_PATIENT_CANCEL_BUTTON_TRANSLATION,
  payload: value,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setPatientSubmitButtonTranslation = value => ({
  type: AT.SET_PATIENT_SUBMIT_BUTTON_TRANSLATION,
  payload: value,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setErrorGeneralMessageTranslation = value => ({
  type: AT.SET_ERROR_GENERAL_MESSAGE_TRANSLATION,
  payload: value,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setFormLastInvalid = formLastInvalid => ({
  type: AT.SET_FORM_LAST_INVALID,
  payload: formLastInvalid,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setIsEditPatient = value => ({
  type: AT.SET_IS_EDIT_PATIENT,
  payload: value,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setIsReadOnlyForm = value => ({
  type: AT.SET_IS_READ_ONLY_FORM,
  payload: value,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setIsPatientSubmitDisabled = value => ({
  type: AT.SET_IS_PATIENT_SUBMIT_DISABLED,
  payload: value,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setIsErrorConflictAlert = isShowErrorConflictAlert => ({
  type: AT.SET_IS_ERROR_CONFLICT_ALERT,
  payload: isShowErrorConflictAlert,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setIsErrorConflictPopup = isShowErrorConflictPopup => ({
  type: AT.SET_IS_ERROR_CONFLICT_POPUP,
  payload: isShowErrorConflictPopup,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const setIsErrorGeneralPopup = isShowErrorGeneralPopup => ({
  type: AT.SET_IS_ERROR_GENERAL_POPUP,
  payload: isShowErrorGeneralPopup,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const changePatientValidationsConfig = validationsConfig => ({
  type: AT.CHANGE_PATIENT_VALIDATIONS_CONFIG,
  payload: validationsConfig,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const checkIfPatientHasConflictsNew = ({
  patient,
  companyId,
  setLoader = false,
  cancelPrevRequest = false
}) => ({
  type: AT.CHECK_IF_PATIENT_HAS_CONFLICTS_NEW.API_REQUEST,
  payload: patient,
  meta: {
    withCredentials: true,
    feature: AT.PATIENT_MANAGEMENT,
    sourceAction: AT.CHECK_IF_PATIENT_HAS_CONFLICTS_NEW,
    url: `${patientSaveUrl}?dryrun=true&companyId=${companyId}`,
    method: 'POST',
    setLoader,
    cancelPrevRequest
  }
});

export const checkIfPatientHasConflictsEdit = ({
  patient,
  companyId,
  setLoader = false,
  cancelPrevRequest = false
}) => ({
  type: AT.CHECK_IF_PATIENT_HAS_CONFLICTS_EDIT.API_REQUEST,
  payload: patient,
  meta: {
    withCredentials: true,
    feature: AT.PATIENT_MANAGEMENT,
    sourceAction: AT.CHECK_IF_PATIENT_HAS_CONFLICTS_EDIT,
    url: `${patientUpdateByIdUrl}?dryrun=true&UID=${patient.uid}&companyId=${companyId}`,
    method: 'PATCH',
    setLoader,
    cancelPrevRequest
  }
});

export const userChangedInputPatient = payload => ({
  type: AT.USER_CHANGED_INPUT_PATIENT,
  payload,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const userClickedOnConfirmErrorGeneralPopup = () => ({
  type: AT.USER_CLICKED_ON_CONFIRM_ERROR_GENERAL_POPUP,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const userClickedOnConfirmConflictPopup = payload => ({
  type: AT.USER_CLICKED_ON_CONFIRM_CONFLICT_POPUP,
  payload,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const changeChartNumberAvailability = config => ({
  type: AT.SET_CHART_NUMBER_AVAILABILITY,
  payload: config,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const changeLastNameValidation = config => ({
  type: AT.SET_LAST_NAME_VALIDATION,
  payload: config,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
})

export const generateInitialChartNumber = isGeneratedChartNumberRequired => ({
  type: AT.SET_CHART_NUMBER_GENERATOR_REQUIRED,
  payload:  isGeneratedChartNumberRequired,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
})

export const userClickedOnCancelPatientForm = () => ({
  type: AT.USER_CLICKED_ON_CANCEL_PATIENT_FORM,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const userClickedOnSubmitPatientForm = payload => ({
  type: AT.USER_CLICKED_ON_SUBMIT_PATIENT_FORM,
  payload,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

// when user clicks on a patient that is already paired. we only send the patient back to rx
export const userClickedOnAnAlreadyPairedPatient = ({ patient }) => ({
  type: AT.USER_CLICKED_ON_AN_ALREADY_PAIRED_PATIENT,
  payload: { patient },
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const updateHostPatient = patient => ({
  type: AT.UPDATE_HOST_PATIENT,
  payload: patient,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});

export const userClickedOnCancelConflictPopup = () => ({
  type: AT.USER_CLICKED_ON_CANCEL_CONFLICT_POPUP,
  meta: {
    feature: AT.PATIENT_MANAGEMENT
  }
});
