import React from 'react';
import Button from 'react-bootstrap/Button';

import Popup from '../Popup';
import { NotificationIcons } from '../Popup/Notification/notificationIconsTypes';

import styles from './QuestionPopup.module.css';

function QuestionPopup(props) {
  const {
    cancelText,
    confirmText,
    message,
    title,
    handleCancelClick,
    handleConfirmClick
  } = props;

  const icon = NotificationIcons.Question;

  const footerActions = (
    <>
      <Button
        className={styles.footerButton}
        onClick={handleCancelClick}
        variant="outline-secondary"
        size="lg"
      >
        {cancelText}
      </Button>
      <Button
        className={styles.footerButton}
        onClick={handleConfirmClick}
        variant="primary"
        size="lg"
      >
        {confirmText}
      </Button>
    </>
  );

  return (
    <Popup
      title={title}
      message={message}
      icon={icon}
      footerActions={footerActions}
    />
  );
}

export default QuestionPopup;
