import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import shell from './core/shell/shell.reducer';
import patientManagement from './feature/patient-management/patient-management.reducer';
import patientSearch from './feature/patient-search/patient-search.reducer';
import sdkInfo from './core/sdk/sdk.reducer';

const reducers = combineReducers({
  form,
  shell,
  patientManagement,
  patientSearch,
  sdkInfo
});

export default reducers;
