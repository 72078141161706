import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import TranslatedContent from '../Translations/TranslatedContent';
import DateInput from '../UI/DateInput/DateInput';
import TextInput from '../UI/TextInput/TextInput';
import RadioButton from '../UI/RadioButton/RadioButton';
import './DynamicInputField.scss';

class DynamicInputField extends React.Component {
  getControl = () => {
    let innerJsx = null,
      isError = true;
    const { input, meta, formFields, showErrorBorder, readOnly } = this.props;

    const { inputType, options, label, name, isRequired, inline, format } = formFields;

    const labelJsx = (
      <Form.Label>
        {<TranslatedContent contentArray={[label]} />}:{isRequired && !readOnly && <span className="required"> *</span>}
      </Form.Label>
    );

    switch (inputType) {
      case 'radio-button':
        // radio button component here
        innerJsx = (
          <Form.Group as={Row} controlId={`radio-buttons-${name}`}>
            {labelJsx}
            <Col>
              <RadioButton options={options} label={label} name={name} readOnly={readOnly} {...input} {...meta} />
            </Col>
          </Form.Group>
        );
        break;
      case 'text':
        // text jsx
        innerJsx = (
          <Form.Group as={Row} controlId={`text-input-${name}`}>
            {labelJsx}
            <Col>
              <TextInput {...input} readOnly={readOnly} {...meta} />
            </Col>
          </Form.Group>
        );
        break;
      case 'date':
        // date jsx

        innerJsx = (
          <Form.Group as={Row} controlId={`date-input-${name}`}>
            {labelJsx}
            <Col>
              <div className="date-input">
                <DateInput {...input} format={format} readOnly={readOnly} {...meta} />
              </div>
            </Col>
          </Form.Group>
        );
        isError = meta.active;
        break;
      default:
        innerJsx = null;
        break;
    }

    const errorJsx = (
      <div
        className={classNames({
          'error-wrapper': isError && meta.touched && meta.error
        })}
      >
        {isError &&
          meta.touched &&
          (meta.error && (
            <span className={classNames('error-text')}>{<TranslatedContent contentArray={meta.error} />}</span>
          ))}
      </div>
    );

    return (
      <div>
        <div
          className={
            classNames('patient-form-input-field', {
              'inline-element': inline
            }) + ((isError && meta.touched && meta.error) || showErrorBorder ? ' error-border' : ' regular-border')
          }
        >
          {innerJsx}
        </div>
        {errorJsx}
      </div>
    );
  };

  render() {
    return <div className="patient-form-input">{this.getControl()}</div>;
  }
}

DynamicInputField.propTypes = {
  formField: PropTypes.shape({
    inputType: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        label: PropTypes.object,
        value: PropTypes.any
      })
    ),
    label: PropTypes.object,
    name: PropTypes.string,
    inline: PropTypes.bool,
    widthColumns: PropTypes.number
  }),
  input: PropTypes.object
};

export default DynamicInputField;
