import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';

import PatientManagementForm from './components/PatientManagementForm';
import PatientSearchContainer from './components/PatientSearch';
import { appInit } from './redux/core/shell/shell.actions';
import { langCodeParamName, defaultLangCode } from './consts';
// Our translated strings and per language locale data
import { translations, localeData } from './translations/translations_combined';
import styles from './App.module.scss';
import PatientAppSpinner from './components/UI/Spinner/Spinner';

const newHistory = createBrowserHistory();

// i18n
addLocaleData(localeData);
const locale = queryString.parse(window.location.search)[langCodeParamName] || defaultLangCode;
const messages = translations[locale.toLowerCase()] || translations.en;

class App extends Component {
  componentDidMount() {
    const urlParams = queryString.parse(window.location.search);
    this.props.appInit(urlParams);
  }

  render() {
    const { showLoader } = this.props;

    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <div className={styles.App}>
          <PatientAppSpinner showSpinner={showLoader} />
          <Router history={newHistory}>
            <div>
              <Route path="/patient" exact component={PatientManagementForm} />
              <Route path="/patient-search" exact component={PatientSearchContainer} />
            </div>
          </Router>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  const { shell } = state;
  return {
    endpoint: shell.fromUrl.endpoint,
    showLoader: shell.showLoader
  };
};

const mapDispatchToProps = {
  appInit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
