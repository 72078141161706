import uuid from 'uuid';

import { fieldValidations } from './formFieldsValidations';
import { patientFormTranslatedStrings } from '../../translations/PatientFormTranslations';
import { GenderType, FieldName } from '../../utils/enums';

const {
  firstName,
  lastName,
  middleInitial,
  gender,
  dateOfBirth,
  chartNumber,
  zipCode,
  maleOption,
  femaleOption
} = patientFormTranslatedStrings;

const formFields = [
  {
    name: FieldName.FIRST_NAME,
    inputType: 'text',
    label: firstName,
    widthColumns: 9,
    isRequired:true,
    isKeyField: true,
    isShowErrorBorder: false,
    isVisible: true,
    isDisabled: false,
    validations: fieldValidations(FieldName.FIRST_NAME)
  },
  {
    name: FieldName.LAST_NAME,
    inputType: 'text',
    label: lastName,
    widthColumns: 9,
    isRequired:true,
    isKeyField: true,
    isShowErrorBorder: false,
    isVisible: true,
    isDisabled: false,
    validations: fieldValidations(FieldName.LAST_NAME)
  },
  {
    name: FieldName.MI,
    inputType: 'text',
    label: middleInitial,
    widthColumns: 9,
    isRequired: false,
    isKeyField: false,
    isShowErrorBorder: false,
    isVisible: false,
    isDisabled: false,
    validations: fieldValidations(FieldName.MI)
  },
  {
    name: FieldName.GENDER,
    inputType: 'radio-button',
    label: gender,
    isRequired: false,
    isKeyField: false,
    isShowErrorBorder: false,
    isVisible: true,
    isDisabled: false,
    options: [
      { id: uuid(), label: maleOption, value: GenderType.Male.value },
      { id: uuid(), label: femaleOption, value: GenderType.Female.value }
    ],
    validations: fieldValidations(FieldName.GENDER)
  },
  {
    name: FieldName.DATE_OF_BIRTH,
    inputType: 'date',
    label: dateOfBirth,
    isRequired: false,
    isKeyField: false,
    isShowErrorBorder: false,
    isVisible: true,
    isDisabled: false,
    format: 'YYYY-MM-DD',
    validations: fieldValidations(FieldName.DATE_OF_BIRTH)
  },
  {
    name: FieldName.CHART_NUMBER,
    inputType: 'text',
    label: chartNumber,
    isRequired: false,
    isKeyField: true,
    isShowErrorBorder: false,
    isVisible: true,
    isDisabled: false,
    validations: fieldValidations(FieldName.CHART_NUMBER)
  },
  {
    name: FieldName.ZIP_CODE,
    inputType: 'text',
    label: zipCode,
    isRequired: false,
    isKeyField: false,
    isShowErrorBorder: false,
    isVisible: false,
    isDisabled: false,
    validations: fieldValidations(FieldName.ZIP_CODE)
  }
];

export const getFieldValidations = validationConfig => {
  if (!validationConfig.name) {
    return [];
  }
  if (!validationConfig.isRequired) {
    return fieldValidations(validationConfig.name);
  }
  return fieldValidations(`${validationConfig.name}Required`);
};

export default formFields;
