import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { sdkInit, sdkCloseMe } from '../../redux/core/sdk/sdk.actions';
import { rxEventTypes } from '../../redux/core/sdk/sdk.enums';
import { SupportedPatientTypes } from '../../utils/enums';
import {
  getPatients,
  userClickedOnSelectPatient,
  userClickedOnCancelConflictPopup,
  userDecideToPairPatient
} from '../../redux/feature/patient-search/patient-search.actions';

import TranslatedContent from '../Translations/TranslatedContent';
import QuestionPopup from '../UI/QuestionPopup';
import { patientSearchTranslatedStrings as messages } from '../../translations/PatientSearchTranslations';

import { doctorTableProps, mapPatientsToTable, pairedDoctorTableProps } from './PatientSearch.utils';
import PatientSearch from './PatientSearch';

class PatientSearchContainer extends Component {
  static propTypes = {
    /**
     * Conflicted patient object if there is one
     */
    conflictedPatient: PropTypes.object,
    /**
     * Get patient list
     */
    getPatients: PropTypes.func.isRequired,

    /**
     * Patients to display
     */
    patients: PropTypes.arrayOf(PropTypes.object),
    /**
     * function to tell the host to close the patient app window
     */
    sdkCloseMe: PropTypes.func.isRequired,
    /**
     *
     */
    sdkInit: PropTypes.func.isRequired,
    /**
     * Handle case of user clicks on select button.
     */
    userClickedOnSelectPatient: PropTypes.func.isRequired,
    /**
     * current company of the doctor.
     */
    companyId: PropTypes.string
  };

  

  selectedPatient = null;


  getTableColumns() {
    const { isDoctorIdsPaired, isZipCodeEnabled } = this.props;

    const { tableColumns } = isDoctorIdsPaired ? pairedDoctorTableProps : doctorTableProps;
    const shouldRemoveZipCodeColumn = isDoctorIdsPaired && !isZipCodeEnabled;

    if (shouldRemoveZipCodeColumn) {
      return tableColumns.filter(col => col.dataKey !== 'zipCode');
    }
    return tableColumns;
  }

  getRawPatient(patientID) {
    const { patients } = this.props;
    const selectedPatient = patients.find(patient => patient.id === patientID);
    const { icon, id, genderText, ...rawPatientData } = selectedPatient;

    return rawPatientData;
  }

  handleCancel = () => {
    const { sdkCloseMe } = this.props;
    sdkCloseMe(rxEventTypes.patientAppSelectedPatient);
  };

  handleRefresh = () => {
    const { getPatients } = this.props;
    const forceCacheUpdate = true;
    getPatients({patientTypes:SupportedPatientTypes, forceCacheUpdate});
  };

  handlePatientSelect = patientID => {
    const { userClickedOnSelectPatient, companyId } = this.props;
    const rawPatientData = this.getRawPatient(patientID);
    this.selectedPatient = rawPatientData;
    userClickedOnSelectPatient(rawPatientData, companyId);
  };

  getConflictMessage() {
    const { conflictedPatient } = this.props;
    const msg = (
      <>
        <TranslatedContent contentArray={[messages.pairMessage]} />
        <br />
        <TranslatedContent contentArray={[conflictedPatient.fullName]} />
        &nbsp;
        <TranslatedContent contentArray={[conflictedPatient.gender ? messages.female : messages.male]} />
        &nbsp;
        <TranslatedContent contentArray={[conflictedPatient.dateOfBirth]} />
        &nbsp;
        <TranslatedContent contentArray={[messages.chartNumber, conflictedPatient.chartNumber]} />
        <br />
        <br />
        <TranslatedContent contentArray={[messages.pairMessageQuestion]} />
      </>
    );
    return msg;
  }

  render() {
    const {
      isDoctorIdsPaired,
      patients,
      conflictedPatient,
      companyId,
      userClickedOnCancelConflictPopup,
      userDecideToPairPatient
    } = this.props;
    const tableProps = isDoctorIdsPaired ? pairedDoctorTableProps : doctorTableProps;
    const tableColumns = this.getTableColumns();

    return (
      <>
        <PatientSearch
          {...tableProps}
          patients={patients}
          tableColumns={tableColumns}
          onCancel={this.handleCancel}
          onPatientSelect={this.handlePatientSelect}
          onRefresh={this.handleRefresh}
        />
        {conflictedPatient && (
          <QuestionPopup
            title={<TranslatedContent contentArray={[messages.question]} />}
            message={this.getConflictMessage()}
            cancelText={<TranslatedContent contentArray={[messages.no]} />}
            confirmText={<TranslatedContent contentArray={[messages.yes]} />}
            handleCancelClick={userClickedOnCancelConflictPopup}
            handleConfirmClick={() => userDecideToPairPatient(this.selectedPatient, companyId)}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    patientSearch: { conflictedPatient, patientsList },
    shell: {
      config: { isDoctorIdsPaired, isZipCodeEnabled },
      fromUrl: { companyId }
    }
  } = state;
  return {
    conflictedPatient,
    isDoctorIdsPaired,
    isZipCodeEnabled,
    companyId,
    patients: mapPatientsToTable(patientsList)
  };
};

const mapDispatchToProps = {
  getPatients,
  sdkCloseMe,
  sdkInit,
  userDecideToPairPatient,
  userClickedOnSelectPatient,
  userClickedOnCancelConflictPopup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientSearchContainer);
