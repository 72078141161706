import { isDate } from 'lodash';
import { required, length, format } from 'redux-form-validators';
import { errorTranslatedStrings } from '../translations/ErrorsTranslations';

// details of how to create/use redux-form-validators is at https://github.com/gtournie/redux-form-validators

// exported
const lengthError = (translatedField, maxLength) =>
  length({
    max: maxLength,
    msg: lengthErrorMessage(translatedField, maxLength),
    memoize: false
  });
const minimalLengthError = (translatedField, maxLength) =>
  length({
    max: maxLength,
    msg: minimalLengthErrorMessage(translatedField),
    memoize: false
  });
const requiredError = () => required({ msg: requiredErrorMessage, memoize: false });

const DateFieldError = translatedField => value => {
  const dateInput = new Date(value);
  if (value) {
    if (dateInput.toDateString() === 'Invalid Date' || !isDate(dateInput) || !value.match(DATE_OF_BIRTH_REGEX)) {
      return formatDateErrorMessage(translatedField);
    }
    const todayZeroHour = dateInput.setHours(0, 0, 0, 0);
    if ( todayZeroHour >= new Date().setHours(0, 0, 0, 0) ||
         todayZeroHour < new Date('1900/01/01')
    ) {
      return DateBoundsErrorMessage;
    }
  }
  return undefined;
};

const middleInitialLengthError = () => length({ max: 1, msg: middleInitialLengthErrorMessage, memoize: false });
const middleInitialCharactersOnlyError = () =>
  format({
    with: NO_SPECIAL_CHARS_REGEX,
    msg: middleInitialCharactersOnlyErrorMessage,
    memoize: false
  });

const emojiPatternError = translatedField =>
  format({
    with: NO_EMOJI_REGEX,
    msg: invalidCharactersErrorMessage(translatedField),
    memoize: false
  });

const patientZipCodePatternError = translatedField =>
  format({
    with: ZIP_CODE_CHARS_REGEX,
    msg: patientZipCodePatternErrorMessage(translatedField),
    memoize: false
  });

// internal
const lengthErrorMessage = (translatedField, maxLength) => [
  translatedField,
  errorTranslatedStrings.lengthErrorMessage(maxLength)
];
const minimalLengthErrorMessage = translatedField => [translatedField, errorTranslatedStrings.minimalLengthErrorMessage];

const invalidCharactersErrorMessage = translatedField => [
  translatedField,
  errorTranslatedStrings.invalidCharactersErrorMessage
];
const patientZipCodePatternErrorMessage = translatedField => [
  translatedField,
  errorTranslatedStrings.zipCodeInvalidCharactersErrorMessage
];
const requiredErrorMessage = [errorTranslatedStrings.requiredErrorMessage];
const DateBoundsErrorMessage = [errorTranslatedStrings.DateBoundsErrorMessage];

const formatDateErrorMessage = translatedField => [translatedField, errorTranslatedStrings.formatDateErrorMessage];

const middleInitialLengthErrorMessage = [errorTranslatedStrings.middleInitialLengthErrorMessage];
const middleInitialCharactersOnlyErrorMessage = [errorTranslatedStrings.middleInitialCharactersOnlyErrorMessage];

const NO_EMOJI_REGEX = /(^$)|(^[^\uD800-\uf8ff]+$)/;
const NO_SPECIAL_CHARS_REGEX = /(^$)|(^[a-zA-Z0-9 ]+$)/;
const ZIP_CODE_CHARS_REGEX = /(^$)|(^[a-zA-Z0-9 ]+$)/;
const DATE_OF_BIRTH_REGEX = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

export default {
  lengthError,
  minimalLengthError,
  requiredError,
  DateFieldError,
  middleInitialLengthError,
  middleInitialCharactersOnlyError,
  emojiPatternError,
  patientZipCodePatternError
};
