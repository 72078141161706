import createMiddleware from '../../utils/middleware-helper';
import * as AT from '../../action-types';
import { sdkInit } from '../sdk/sdk.actions';
import {
  getAppConfig,
  setAppConfig,
  changeZipCodeVisibility,
  setUrlParams,
  changeUrlParams,
  appReady
} from './shell.actions';

import {
  changeChartNumberAvailability,
  changeLastNameValidation,
  generateInitialChartNumber
} from '../../feature/patient-management/patient-management.actions'
import { patientTypeParamName, companyIdUrlParamName } from '../../../consts';

export const middleware = ({ action, dispatch }) => {
  const { payload, type } = action;

  switch (type) {
    case AT.APP_INIT: {
      const urlParams = payload;
      const sdkRequest = {};

      // patch for undefined patientType, ITEROBIZ-39520
      if (isNaN(Number(urlParams[patientTypeParamName]))) {
        urlParams[patientTypeParamName] = '1';
      }

      dispatch([setUrlParams(urlParams), changeUrlParams(urlParams), getAppConfig({ companyId: urlParams[companyIdUrlParamName] }), sdkInit({ sdkRequest })]);
      break;
    }

    case AT.GET_APP_CONFIG.SUCCESS: {
      const config = payload.data;
      if (config.regulatorySettings != null) {
        dispatch([setAppConfig(config), changeZipCodeVisibility(config.isZipCodeEnabled), appReady(), generateInitialChartNumber(true),
          changeChartNumberAvailability(config.regulatorySettings.isChartNumberDisabled), 
          changeLastNameValidation(config.regulatorySettings.maxPatientLastNameLength)]);
      } else {
        dispatch([setAppConfig(config), changeZipCodeVisibility(config.isZipCodeEnabled), appReady()]);
      }
      
      break;
    }
    default:
    // do nothing
  }
};

export default createMiddleware({
  feature: AT.SHELL
})(middleware);
