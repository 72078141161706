// url parameters
export const langCodeParamName = 'lang-code';
export const baseUrlParamName = 'endpoint';
export const patientIdUrlParamName = 'id';
export const companyIdUrlParamName = 'companyid';
export const patientTypeParamName = 'type';
export const defaultLangCode = 'en-us';

// api
export const urls = {
  patientSaveUrl: '/patients/save',
  patientUpdateByIdUrl: '/patients/update',
  patientsGetAllUrl: '/patients/',
  patientsGetByIdUrl: '/patients/get',
  patientAppConfigUrl: '/patientappconfig/get'
};
