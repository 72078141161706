import { deburr, isObject } from 'lodash';

const isString = object => Object.prototype.toString.call(object) === '[object String]';

const formatDateString = dateStr => dateStr ? dateStr.split('T').shift() : null

const isGuid = stringToTest => {
  if (!stringToTest) {
    return false;
  }
  if (stringToTest[0] === '{') {
    stringToTest = stringToTest.substring(1, stringToTest.length - 1);
  }
  const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(stringToTest);
};

const isValidUrl = url => {
  const pattern = new RegExp('^((https|http)+:\\/\\/)+' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(url);
};

const isTranslationObject = object =>
  Object.prototype.toString.call(object) === '[object Object]' && object.hasOwnProperty('id');

const log = value => {
  if (isObject(value)) {
    console.log(JSON.stringify(value));
  } else {
    console.log(value);
  }
};

const logMessage = (message, value) => {
  console.log(JSON.stringify({ message, value }));
};

const normalizeString = str => deburr(str).toLowerCase();

const stringToWords = str => str.trim().split(/ +/);

const isStringContainsAny = (str, subStringArr) => {
  if (!subStringArr || !str) {
    return '';
  }
  return subStringArr.some(item => {
    return str.indexOf(item) >= 0;
  });
};

const arrayToQueryString = (arr, stringToConcatenate) => {
  if (!arr || !stringToConcatenate) {
    return '';
  }
  return arr.reduce((query, value) => {
    return `${query}&${stringToConcatenate}=${value}`;
  }, '');
};

export default {
  isString,
  formatDateString,
  isGuid,
  isValidUrl,
  isTranslationObject,
  normalizeString,
  log,
  logMessage,
  stringToWords,
  isStringContainsAny,
  arrayToQueryString
};
