import React from 'react';

import Footer from '../Footer';
import Header from '../Header';

import Notification from './Notification';
import styles from './Popup.module.css';

function Popup(props) {
  const { footerActions, icon, message, title } = props;

  return (
    <>
      <div className={styles.overlay} />
      <section className={styles.container}>
        <Header className={styles.header} title={title} />
        <Notification className={styles.body} icon={icon} message={message} />
        <Footer className={styles.footer} actions={footerActions} />
      </section>
    </>
  );
}

export default Popup;
