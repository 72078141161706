export const patientFormTranslatedStrings = {
  firstName: {
    id: 'patientForm.firstName',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'patientForm.lastName',
    defaultMessage: 'Last Name'
  },
  middleInitial: {
    id: 'patientForm.middleInitial',
    defaultMessage: 'MI'
  },
  gender: {
    id: 'patientForm.gender',
    defaultMessage: 'Gender'
  },
  dateOfBirth: {
    id: 'patientForm.dateOfBirth',
    defaultMessage: 'Date Of Birth'
  },
  chartNumber: {
    id: 'patientForm.chartNumber',
    defaultMessage: 'Chart #'
  },
  zipCode: {
    id: 'patientForm.zipCode',
    defaultMessage: 'Zip Code #'
  },
  zipCodeErrorName: {
    id: 'patientForm.zipCodeErrorName',
    defaultMessage: 'Patient zip code'
  },
  maleOption: {
    id: 'patientForm.gender.maleOption',
    defaultMessage: 'Male'
  },
  femaleOption: {
    id: 'patientForm.gender.femaleOption',
    defaultMessage: 'Female'
  },
  buttonUpdate: {
    id: 'patientForm.buttons.update',
    defaultMessage: 'Update'
  },
  buttonAdd: {
    id: 'patientForm.buttons.add',
    defaultMessage: 'Add'
  },
  buttonCancel: {
    id: 'patientForm.buttons.cancel',
    defaultMessage: 'Cancel'
  },
  buttonOk: {
    id: 'patientForm.buttons.ok',
    defaultMessage: 'OK'
  },
  headerEdit: {
    id: 'patientForm.headers.edit',
    defaultMessage: 'Edit Patient'
  },
  headerEditDisabled: {
    id: 'patientForm.headers.editDisabled',
    defaultMessage: 'Patient Details'
  },
  headerNew: {
    id: 'patientForm.headers.new',
    defaultMessage: 'New Patient'
  },
  conflictPopupMessage: {
    id: 'patientForm.conflictPopupMessage',
    defaultMessage: 'A patient with the same details already exists:'
  },
  conflictPopupQuestion: {
    id: 'patientForm.conflictPopupQuestion',
    defaultMessage: 'Is this the same patient?'
  },
  female: {
    id: 'patientForm.female',
    defaultMessage: 'Female'
  },
  fullName: {
    id: 'patientForm.fullName',
    defaultMessage: 'Full Name'
  },
  male: {
    id: 'patientForm.male',
    defaultMessage: 'Male'
  },
  conflictHeader: {
    id: 'patientForm.conflictHeader',
    defaultMessage: 'A patient with the same details already exists:'
  },
  yes: {
    id: 'patientForm.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'patientForm.no',
    defaultMessage: 'No'
  },
  errorGeneralHeader: {
    id: 'patientForm.errorGeneralHeader',
    defaultMessage: 'Warning'
  },
  errorGeneralMessageNew: {
    id: 'patientForm.errorGeneralMessageNew',
    defaultMessage: 'Patient creation failed. Please try again.'
  },
  errorGeneralMessageEdit: {
    id: 'patientForm.errorGeneralMessageEdit',
    defaultMessage: 'Failed to update patient details. Please try again.'
  },
  question: {
    id: 'patientForm.question',
    defaultMessage: 'Question'
  }
};
