export const SdkEvents = {
  Init: 0,
  SendMessage: 1
};

export const SdkResult = {
  Success: 0,
  Failed: 1
};

export const SdkMessages = {
  Init: 'Init Patient App',
  SuccessInit: 'Patient App is Alive',
  SuccessSave: 'Patient was successfully Saved',
  FailureSave: 'Failed to save patient',
  PatientAlreadyExists: 'Patient already exists'
};

export const sdkRawMessage = {
  sdkRequest: {
    origin: '*',
    message: '',
    result: '',
    event: ''
  },
  data: {
    eventId: null,
    data: null
  }
};

export const rxEventTypes = {
  patinetappReady: 100,
  patientAppAddPatient: 101,
  patientAppSelectedPatient: 102,
  patientAppUpdateHostPatient: 103,
  patientAppSelectedPatientConflict: 104,
  patientAppSetPatientValidationsConfig: 105,
  patientAppRedirect: 106
};
