import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import Button from 'react-bootstrap/Button';
import Highlighter from 'react-highlight-words';
import { defaultTableRowRenderer } from 'react-virtualized';
import { sortBy } from 'lodash';
import classNames from 'classnames';

import utils from '../../utils/utils';
import { patientSearchTranslatedStrings as messages } from '../../translations/PatientSearchTranslations';
import TranslatedContent from '../Translations/TranslatedContent';
import Table from '../UI/Table';
import SearchField from '../UI/SearchField';
import Header from '../UI/Header';
import Footer from '../UI/Footer';

import NoResults from './NoResults';
import styles from './PatientSearch.module.scss';

class PatientSearch extends Component {
  static propTypes = {
    /**
     * Column dataKeys to search by
     */
    searchColumnKeys: PropTypes.arrayOf(PropTypes.string),
    /**
     * Column dataKeys to sort by
     */
    sortColumnKeys: PropTypes.arrayOf(PropTypes.string),
    /**
     * Table columns
     */
    tableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        label: PropTypes.node,
        width: PropTypes.number.isRequired
      })
    ).isRequired,

    /**
     * @ignore
     */
    intl: intlShape.isRequired,
    /**
     * Callback, fired when clicking the Cancel button
     */
    onCancel: PropTypes.func.isRequired,
    /**
     * Callback, fired when clicking the Select Patient button
     */
    onPatientSelect: PropTypes.func.isRequired,
    /**
     * Patients to display
     */
    patients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ).isRequired
  };

  static defaultProps = {
    searchColumnKeys: [],
    sortColumnKeys: []
  };

  state = {
    searchValue: '',
    selectedPatientID: '',
    animateRefreshButton: false
  };

  isMinSearchChars() {
    return this.state.searchValue.length > 2;
  }

  filterPatients = (patients, value) => {
    const { tableColumns, searchColumnKeys } = this.props;
    const searchWords = utils.stringToWords(utils.normalizeString(value));
    const allColumnKeys = tableColumns.map(col => col.dataKey);

    return patients.filter(patient => {
      const searchFields = (searchColumnKeys.length ? searchColumnKeys : allColumnKeys).map(key =>
        utils.normalizeString(patient[key])
      );

      return searchWords.every(value => {
        return searchFields.some(field => field.includes(value));
      });
    });
  };

  sortPatients = patients => {
    const { sortColumnKeys } = this.props;

    if (!sortColumnKeys.length) {
      return patients;
    }

    const keys = sortColumnKeys.map(key => patient =>
      typeof patient[key] === 'string' ? patient[key].toLowerCase() : ''
    );
    return sortBy(patients, keys);
  };

  togglePatientSelect = patientID => {
    this.setState(prevState => {
      const selectedPatientID = prevState.selectedPatientID === patientID ? '' : patientID;
      return { selectedPatientID };
    });
  };

  highlightWord(text, value) {
    const searchWords = utils.stringToWords(value);

    return (
      <Highlighter
        highlightClassName={styles.highlightedWord}
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={text}
      />
    );
  }

  handleSearchFieldChange = event => {
    const searchValue = event.target.value;
    this.setState({ searchValue });
  };

  handleTableRowClick = ({ rowData }) => {
    const { id } = rowData;
    this.togglePatientSelect(id);
  };

  handleCancelClick = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleRefreshClick = () => {
    const { onRefresh } = this.props;
    this.setState({ animateRefreshButton: true }, () => {
      setTimeout(() => {
        this.setState({ animateRefreshButton: false });
      }, 1000);
    });
    onRefresh();
  };

  handleSelectPatientClick = () => {
    const { onPatientSelect } = this.props;
    onPatientSelect(this.state.selectedPatientID);
  };

  renderTableRow = props => {
    const { className: classNameProp, ...other } = props;

    const className = classNames(classNameProp, styles.row, {
      [styles.selectedRow]: this.state.selectedPatientID === props.rowData.id
    });

    return defaultTableRowRenderer({ className, ...other });
  };

  renderTableCell = cell => {
    const { cellData } = cell;

    if (!cellData) {
      return null;
    }

    if (typeof cellData === 'string' && this.isMinSearchChars()) {
      const { searchColumnKeys } = this.props;

      const shouldHighlightWord = searchColumnKeys.length ? searchColumnKeys.includes(cell.dataKey) : true;

      if (shouldHighlightWord) {
        return this.highlightWord(cellData, this.state.searchValue);
      }
    }

    return cellData;
  };

  renderNoResults = () => {
    let message = messages.typeAtLeast3Letters;

    if (this.state.searchValue !== '' && this.state.searchValue.length >= 3) {
      message = messages.noResults;
    }
    
    return <NoResults message={message} />;
  };

  render() {
    const { tableColumns, intl, patients: patientsProp } = this.props;

    const patients = this.isMinSearchChars()
      ? this.sortPatients(this.filterPatients(patientsProp, this.state.searchValue))
      : [];

    // TODO: uncomment this in 20B
    // const refreshAnimationClass = this.state.animateRefreshButton ? styles.refreshButtonAnimation : '';
    // const refreshButtonClass = classNames([styles.refreshButton, refreshAnimationClass]);

    const headerActions = (
      <section className={styles.headerActionsWrapper}>
        <SearchField
          className={styles.searchField}
          onChange={this.handleSearchFieldChange}
          value={this.state.searchValue}
          placeholder={intl.formatMessage(messages.typeAtLeast3Letters)}
        />
        {/* // Note: It WILL be needed in 20B, so we need just to comment it out for this version
        <Button className={refreshButtonClass} onClick={this.handleRefreshClick} variant="link"></Button>
        */}
      </section>
    );

    const disableSelectButton =
      !this.state.selectedPatientID ||
      !patients.find(p => {
        return p.id === this.state.selectedPatientID;
      });

    const footerActions = (
      <>
        <Button className={styles.cancelButton} onClick={this.handleCancelClick} variant="outline-secondary" size="lg">
          <TranslatedContent contentArray={[messages.cancel]} />
        </Button>
        <Button
          className={styles.selectPatientButton}
          disabled={disableSelectButton}
          onClick={this.handleSelectPatientClick}
          variant="primary"
          size="lg"
        >
          <TranslatedContent contentArray={[messages.selectPatient]} />
        </Button>
      </>
    );

    const tableProps = {
      noRowsRenderer: this.renderNoResults,
      onRowClick: this.handleTableRowClick,
      rowRenderer: this.renderTableRow
    };

    const columnProps = {
      cellRenderer: this.renderTableCell
    };

    return (
      <section className={styles.root}>
        <Header title={<TranslatedContent contentArray={[messages.searchPatient]} />} actions={headerActions} />

        <div className={styles.content}>
          <Table columns={tableColumns} data={patients} tableProps={tableProps} columnProps={columnProps} />
        </div>

        <Footer className={styles.footer} actions={footerActions} />
      </section>
    );
  }
}

export default injectIntl(PatientSearch);
