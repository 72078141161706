import createMiddleware from '../../utils/middleware-helper';
import * as AT from '../../action-types';
import { sdkSendMessage } from './sdk.actions';
import { changePatientValidationsConfig } from '../../feature/patient-management/patient-management.actions';
import { SdkEvents, SdkMessages, SdkResult, sdkRawMessage, rxEventTypes } from './sdk.enums';

const { SDK } = AT;

export const middleware = ({ action, dispatch }) => {
  const handleMessageEvent = event => {
    const eventId = +event.data.eventId;
    const data = event.data.data;
    switch (eventId) {
      case rxEventTypes.patientAppSetPatientValidationsConfig:
        dispatch(changePatientValidationsConfig(data));
        break;
      default:
        break;
    }
  };

  const sdkSendMessageappReady = {
    sdkRequest: {
      origin: '*',
      message: SdkMessages.SuccessInit,
      result: SdkResult.Success,
      event: SdkEvents.Init
    },
    data: {
      eventId: rxEventTypes.patinetappReady,
      data: 'Hello from Patient App'
    }
  };

  const { payload, type, sdkRequest } = action;

  switch (type) {
    case AT.SDK_INIT: {
      window.addEventListener('message', handleMessageEvent, false);
      dispatch(sdkSendMessage(sdkSendMessageappReady));
      break;
    }
    case AT.SDK_SEND_MESSAGE: {
      window.parent.postMessage({ ...payload, ...sdkRequest }, sdkRequest.origin);
      break;
    }
    case AT.SDK_CLOSE_ME: {
      const closeMeMessage = {
        ...sdkRawMessage,
        data: {
          eventId: payload,
          data: null
        }
      };
      dispatch(sdkSendMessage(closeMeMessage));
      break;
    }
    default:
      break;
  }
};

export default createMiddleware({
  feature: SDK
})(middleware);
